<template>
    <van-field
        readonly
        clickable
        :name="name+'_'+displayField"
        :label="label"
        :placeholder="placeholder"
        @click="click"
        :required="required"
        :rules="rules"
        v-model="text"
        v-show="show"
    >
    </van-field>
      <van-field
          readonly
          clickable
          :name="name"
          :label="label"
          :required="required"
          :rules="rules"
          v-model="textValue"
          v-show="false"
      >
    </van-field>


    <van-popup v-model:show="showPicker"
               :style="{ height: '80%' }"
               position="bottom"
               :lazy-render='false'
               teleport="body"
    >
      <van-search
          :label="label"
          v-if="canSearch"
          v-model="searchText"
          placeholder="点击此处搜索"
          show-action
          @search="onSearch"
      > <template #action>
        <div @click="onSearch">搜索</div>
      </template></van-search>
      <van-picker
          show-toolbar
          :title="label"
          :columns="columns"
          :columns-field-names="{ text: displayField}"
          @confirm="onConfirm"
          @change="onChange"
          @cancel="showPicker = false"
          :default-index="index"
      >
        <template #columns-top>
          <div style="text-align: right;margin: 4.8vw;color: cadetblue" @click="emptyAll">清空选择</div>
        </template>
      </van-picker>
    </van-popup>
</template>

<script>
import {ref} from "vue";
import _ from "lodash";
import {Toast} from "vant";

export default {
  props: {
    name: {
      default: "picker",
      type: String
    },
    show: {
      default: true,
      type: Boolean
    },
    readonly: {
      default: false,
      type: Boolean
    },
    value: {
      default: "",
      type: String
    },
    displayName: {//默认显示的文字
      default: "",
      type: String
    },
    label: {
      default: "选择",
      type: String
    },
    onClick: {
      default: null,
      type: Function
    },
    placeholder: {
      default: "点击选择",
      type: String
    },
    displayField: {//作为显示的字段
      default: "name",
      type: String
    },
    valueField: {//作为值的字段
      default: "id",
      type: String
    },
    searchParam: {
      default: "",
      type: String
    },
    defConfig: {
      default: null,
      type: Object
    },
    defSelectValCallback: {
      default: null,
      type: Function
    },
    required: {
      default: false,
      type: Boolean
    },
    rules: {
      default: null,
      type: Array
    },
    canSearch: {
      default: false,
      type: Boolean
    },
    selectFirst: {
      default: false,
      type: Boolean
    },
    autoSearch: {
      default: false,
      type: Boolean
    },

  },
  emits: ['onConfirm', 'update:value', 'onChange'],
  data() {
    return {
      firstLoad: false,
      showPicker: false,
      text: "",
      index: 0,
      searchText: '',
      textValue: '',
      columns: [],
    };
  },
  methods: {
    emptyAll() {
      this.$emit('update:value', '');
      this.showPicker = false;
      this.$emit('onConfirm', '');
    },
    click() {
      if (this.$props.readonly) {
        return;
      }
      if (_.isFunction(this.onClick)) {
        var result = this.onClick();
        if (!result) {
          return;
        }
      }
      this.showPicker = true;
      this.getColumns();
    },
    onSearch() {
      var searchText = this.searchText;
      this.getColumns(searchText);
    },
    onConfirm(value, index) {
      if (_.isEmpty(value)) {
        return;
      }
      // this.text = value[this.displayField]
      this.$emit('update:value', value[this.valueField]);
      this.showPicker = false;
      this.$emit('onConfirm', value);
    },
    onChange(value, index) {
      this.$emit('onChange', value);
    },
    setValue(value) {
      var me = this;
      if (_.isEmpty(value)) {
        me.text = '';
        me.$emit("update:value", '');
        me.index = -1;
        return;
      }
      me.columns = [];
      var portName = me.defConfig.portName;
      if (_.isEmpty(portName)) {
        return;
      }
      var requestData = me.defConfig.data;
      if (_.isEmpty(requestData)) {
        requestData = {}
      }
      var option = {
        portName: portName,
        data: requestData,
        needLoading: false,
        pageIndex: 1,
        pageSize: 100,
        successCallback: function (data) {
          var entities = data.entities;
          me.columns = entities;
          me.textValue = value;
          var length = me.columns.length;
          for (let index = 0; index < length; index++) {
            var raw = me.columns[index];
            if (value == raw[me.valueField]) {
              me.text = raw[me.displayField];
              me.$emit("update:value", raw[me.valueField]);
              me.$emit('onConfirm', raw);
              me.index = index;
              break;
            }
          }
        }
      };
      this.$sapi.callPort(option);
    },
    getColumns(searchText) {
      var me = this;
      me.columns = [];
      var portName = me.defConfig.portName;
      if (_.isEmpty(portName)) {
        return;
      }
      var requestData = me.defConfig.data;
      if (_.isEmpty(requestData)) {
        requestData = {}
      }
      var option = {
        portName: portName,
        data: requestData,
        pageIndex: 1,
        pageSize: 100,
        needLoading: false,
        successCallback: function (data) {
          var entities = data.entities;
          me.columns = entities;
          var length = me.columns.length;
          if (!_.isEmpty(me.$props.value)) {
            me.textValue = me.$props.value;
            for (let index = 0; index < length; index++) {
              var raw = me.columns[index];
              if (me.$props.value == raw[me.valueField]) {
                me.text = raw[me.displayField];
                me.$emit("update:value", raw[me.valueField]);
                me.$emit('onConfirm', raw);
                me.index = index;
                break;
              }
            }
          } else {
            if (_.isFunction(me.defSelectValCallback)) {
              for (let index = 0; index < length; index++) {
                raw = me.columns[index];
                if (me.defSelectValCallback(raw)) {
                  me.text = raw[me.displayField];
                  me.textValue = raw[me.valueField];
                  me.$emit('update:value', raw[me.valueField]);
                  me.$emit('onConfirm', raw);
                  me.index = index;
                  break;
                }
              }
            } else if (me.selectFirst) {
              if (!_.isEmpty(me.columns)) {
                raw = me.columns[0];
                me.text = raw[me.displayField];
                me.textValue = raw[me.valueField];
                me.$emit('update:value', raw[me.valueField]);
                me.$emit('onConfirm', raw);
                me.index = 0;
              }
            }
          }
          me.firstLoad = true;
        }
      };
      if (this.canSearch) {
        if (_.isEmpty(me.$props.searchParam)) {
          option.data[me.displayField] = searchText;
        } else {
          option.data[me.$props.searchParam] = searchText;
        }
      }
      this.$sapi.callPort(option);
    },
    init() {
      var me = this;
      if (me.firstLoad) {
        return;
      }
      if (_.isEmpty(me.$props.value) && !_.isFunction(me.defSelectValCallback)
      && !me.selectFirst) {
        return;
      }
      me.getColumns();
    },
    getSelectedRaw() {
      return this.columns[this.index]
    }
  },
  computed: {},
  mounted() {
    var me = this;
    me.init();
  },
  beforeUpdate() {
    var me = this;
    me.init();
  },
  watch: {
    value: function (val, oldval) {
      this.textValue = val;
      this.text = '';
      this.index = 0;
      var length = this.columns.length;
      if (length == 0 && !_.isEmpty(val)) {
        this.setValue(val)
      } else {
        for (let index = 0; index < length; index++) {
          var raw = this.columns[index];
          if (val == raw[this.valueField]) {
            this.text = raw[this.displayField];
            this.index = index;
            break;
          }
        }
      }
    },
    displayName: {
      handler: function (val) {
        this.text = val;
      },
      immediate: true,
    },
    searchText: function (val) {
      if (!this.autoSearch || !this.canSearch) {
        return;
      }
      if (_.isEmpty(val)) {
        this.getColumns('');
      } else {
        this.onSearch();
      }
    },
  }
}
</script>

<style scoped>

</style>
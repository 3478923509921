<template>
  <van-cell-group :title="title">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </van-cell-group>
</template>

<script>
import _ from "lodash";
import {Toast} from "vant";

export default {
  name: "GridList",
  props: {
    title: {
      default: "",
      type: String
    },
    portName: {
      default: "",
      type: String,
      required: true,
    },
    anonymous: {
      default: false,
      type: Boolean,
      required: false,
    },
    requestData: {
      default: null,
      type: Object,
    },
    dataList: {
      default: null,
      type: Array,
      required: true,
    },
    pageSize: {
      default: 30,
      type: Number
    },

  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      page: 1,
      canClick: true,
    }
  },
  mounted() {
    if(!this.onTitleBtnClick) {
      this.canClick= false
    }
  },
  methods: {
    onLoad() {
      const option = {
        portName: this.portName == null ? '' : this.portName,
        data: this.requestData == null ? {} : this.requestData,
        pageIndex: this.page,
        pageSize: this.pageSize,
        needLoading: true,
        anonymous: this.anonymous,
        successCallback: (data) => {
          var entities = data.entities;
          if (this.page === 1) {
            if (data.totalcount === 0) {  		// 判断获取数据条数若等于0
              this.$props.dataList.length = 0;					// 清空数组
              this.finished = true;		// 停止加载
            }
            // 若数据条数不等于0
            this.total = data.totalcount;// 给数据条数赋值
          }
          this.$props.dataList.push(...entities);	// 将数据放入list中
          this.loading = false;			// 加载状态结束
          this.refreshing = false;
          if (this.dataList.length >= this.total) {
            this.finished = true;		// 结束加载状态
            this.refreshing = false;
          } else {
            this.page++;// 分页数加一
          }
        }
      };
        this.$sapi.callPort(option);
      if (_.isEmpty(this.$sapi.getUserInfo())){
        this.$router.replace('/login')
      }
    },
    onRefresh: function () {
      this.finished = false; 		// 清空列表数据
      this.loading = true; 			// 将 loading 设置为 true，表示处于加载状态
      this.page = 1;				// 分页数赋值为1
      this.$props.dataList.length = 0;				// 清空数组
      this.onLoad(); 				// 重新加载数据
    },
  }
}
</script>

<style scoped>
</style>
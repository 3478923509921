<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
  <van-cell-group title="出库管理" inset>
    <van-field
        v-model="loadCarName"
        name="loadCarName"
        label="名称"
    ></van-field>

    <van-field
        v-model="loadCarCode"
        name="loadCarCode"
        label="编码"
        :readonly="true"
    ></van-field>

    <Picker
        name="shipType.id"
        label="发货方式"
        placeholder="请选择发货方式"
        displayField="name"
        valueField="id"
        :required=true
        v-model:value="this.$data['shipType.id']"
        :defConfig="{
              portName:'system.foundation.Dic.getChildDicsByCode',
              data: {'code': 'SHIPPING_METHOD' },
            }"
    ></Picker>

    <Picker
        name="transportType.id"
        label="运输类型"
        placeholder="请选择运输类型"
        displayField="name"
        valueField="id"
        :required=true
        v-model:value="this.$data['transportType.id']"
        :defConfig="{
              portName:'system.foundation.Dic.getChildDicsByCode',
              data: {'code': 'LOAD_ORDER_TYPE' },
            }"
    ></Picker>

    <van-field
        v-model="carCode"
        name="carCode"
        label="车牌号"
    ></van-field>
    <van-field
        v-model="driverName"
        name="driverName"
        label="司机"
    ></van-field>
  </van-cell-group>
  <div>
    <van-button v-if="savebuttonShow" round block type="primary" @click="saveLoadcarOrder()">
      保存装车单
    </van-button>
  </div>

  <van-cell-group v-if="buttonShow" title="扫描箱号条码" inset>
    <van-cell>
    <van-field
        v-model="packageCode"
        name="propertyCode"
        label="箱号"
        placeholder="请扫描箱号"
        required
        @focus="this.focusModel= 'packageCode'"
    ></van-field>
    <van-button round block type="primary" @click="this.show= true,lastShow='packageCode'" style="height: 5.33333vw">
      扫描二维码
    </van-button>
    </van-cell>

    <van-cell>
    <van-field
        v-model="sortOrderCode"
        name="sortOrderCode"
        label="排序单"
        placeholder="扫描排序单看板号"
        @focus="this.focusModel= 'sortOrderCode'"
    ></van-field>
    <van-button round block type="primary" @click="this.show= true,lastShow='sortOrderCode'" style="height: 5.33333vw">
      扫描二维码
    </van-button>
    </van-cell>
  </van-cell-group>

  <div style="background-color: #fafafa" v-if="buttonShow">
    <GridList
        v-if="loadCarId"
        name="materialGrid" title="出库客户订单"   port-name="fr.order.StockInDetail.getStockInDetails"
        :request-data="{'stockIn.id':1}" :immediate-check=false
        :dataList="customDataList" style="margin: 4.26667vw;" ref="grid">
      <ListCard
          v-for="item in customDataList"
          :key="item.id"
          :id="item.id"
      >
        <div class="item">
          <div class="content">
            <div class="tags fs16">{{ '出库单名称：' + item['outStockName'] }}</div>
            <div class="tags fs12">{{ '出库单编码：' + item['outStockCode'] }}</div>
          </div>
        </div>
      </ListCard>
    </GridList>
  </div>
  <van-cell-group title="物料列表" v-if="buttonShow" inset>
    <van-tabs v-model:active="active" @change="onTabChange" type="card" :lazy-render='false'>
      <van-tab title="未扫描" name="unCheck">
        <div style="background-color: #fafafa">
          <GridList name="materialGrid" title=""
                    port-name="fr.order.StockInDetail.getStockInDetails"
                    :request-data="{'stockIn.id':1}"
                    :immediate-check=false
                    :dataList="materialDataListNotCheck" ref="grid">
            <ListCard
                v-for="item in materialDataListNotCheck"
                :key="item.id"
                :id="item.id"
            >
              <div class="item">
                <div class="fs12">
                  <div class="tags fs12">{{ '箱号：' + item['packageCode'] }}</div>
                  <div class="tags fs12">{{ '物料名称：' + item['material.name'] }}</div>
                  <div class="tags fs12">{{ '物料编码：' + item['material.code'] }}</div>
                  <div class="tags fs12">{{ '数量：' + item['qty'] }}</div>
                </div>
              </div>
            </ListCard>
          </GridList>
        </div>

      </van-tab>
      <van-tab title="已扫描" name="checked">
        <div style="background-color: #fafafa">
          <GridList name="materialGrid1" title=""
                    port-name=""
                    :immediate-check=false
                    :dataList="materialDataListHadCheck" ref="checkedGrid">
            <ListCard
                v-for="item in materialDataListHadCheck"
                :key="item.id"
                :id="item.id"
            >
              <div class="item">
                <div class="fs12">
                  <div class="tags fs12">{{ '箱号：' + item['packageCode'] }}</div>
                  <div class="tags fs12">{{ '物料名称：' + item['material.name'] }}</div>
                  <div class="tags fs12">{{ '物料编码：' + item['material.code'] }}</div>
                  <div class="tags fs12">{{ '数量：' + item['qty'] }}</div>
                  <div class="tags fs12">{{ '顺序号：' + item['sortCode'] }}</div>

                </div>
              </div>
            </ListCard>
          </GridList>
        </div>
      </van-tab>
    </van-tabs>
  </van-cell-group>
  <div>
    <van-button v-if="buttonShow" round block type="primary" @click="submitLoadCar()">
      提交装车单明细并发车
    </van-button>
  </div>
</template>

<script>
import GridList from "../../components/GridList";
import ListCard from "../../components/ListCard";
import Picker from "@/components/Picker.vue";
import {Toast} from "vant";
import {toRegExp} from "eslint-plugin-vue/lib/utils/regexp";
import QRReader from "@/components/QRReader.vue";

export default {
  name: "PropertyOutStockDetail",
  components: {GridList, ListCard, Picker,QRReader},

  data() {
    return {
      show:false,
      lastShow:"",
      active: '',
      driverName: '',
      sortOrderCode: '',
      loadCarId: '',
      loadCarName: '',
      loadCarCode: '',
      packageCode: '',
      'shipType.id': '',
      'transportType.id': '',
      carCode: '',
      focusModel: '',
      customDataList: [],
      materialDataListHadCheck: [],
      materialDataListNotCheck: [],
      buttonShow: true,
      savebuttonShow: true
    }
  },
  watch: {
    $route() {
      this.loadCarId = this.$route.query.loadCarId;
      this.loadCarName = this.$route.query.loadCarName;
      this.loadCarCode = this.$route.query.loadCarCode;
      this['shipType.id'] = this.$route.query.shipType;
      this.carCode = this.$route.query.headCarCode;
      this['transportType.id'] = this.$route.query.typeDic;
      this.driverName = this.$route.query.driverName;
      console.log(this.$route)
      if (this.$route.query.statusDic === "LOAD_ORDER_STATUS_UNLOAD" && this.loadCarId !== "" && this.loadCarId !== undefined) {
        this.buttonShow =true ;
      } else {
        this.buttonShow =false ;
      }
      if (this.$route.query.statusDic === "LOAD_ORDER_STATUS_UNLOAD" || this.loadCarId ==="" || this.loadCarId === undefined) {
        this.savebuttonShow =true ;
      } else {
        this.savebuttonShow =false ;
      }


    }
  },
  created() {
    this.loadCarId = this.$route.query.loadCarId;
    this.loadCarName = this.$route.query.loadCarName;
    this.loadCarCode = this.$route.query.loadCarCode;
    this['shipType.id'] = this.$route.query.shipType;
    this.carCode = this.$route.query.headCarCode;
    this['transportType.id'] = this.$route.query.typeDic;
    this.driverName = this.$route.query.driverName;
    console.log(this.$route)
    if (this.$route.query.statusDic === "LOAD_ORDER_STATUS_UNLOAD" && this.loadCarId !== "" && this.loadCarId !== undefined) {
      this.buttonShow =true ;
    } else {
      this.buttonShow =false ;
    }
    if (this.$route.query.statusDic === "LOAD_ORDER_STATUS_UNLOAD" || this.loadCarId ==="" || this.loadCarId === undefined) {
      this.savebuttonShow =true ;
    } else {
      this.savebuttonShow =false ;
    }

    this.getScanCode();

  },
  methods: {
    onRead(text) {
      var me=this;
      if(this.lastShow ==='packageCode') {
        me.packageCode = text;
        var option = {
          portName: 'fr.order.out.PickOrderDetail.getCustomAndMaterial',
          data: {'packageCode': me.packageCode},
          needLoading: true,
          successCallback: function (data) {

            var index = me.customDataList.findIndex(item => {
              if (item.outStockId == data.data.outStockId) {
                return true;
              }
            })
            if (index === -1) {
              me.customDataList.push({
                outStockId: data.data.outStockId,
                outStockName: data.data.outStockName,
                outStockCode: data.data.outStockCode
              })
              data.entities.forEach(item => {
                console.log(item.packageCode+','+me.packageCode)
                if (item.packageCode === me.packageCode) {
                  me.materialDataListHadCheck.push({
                    pid: item.id,
                    packageCode: item.packageCode,
                    'material.name': item['material.name'],
                    'material.code': item['material.code'],
                    'material.supplier.cnName': item['material.supplier.cnName'],
                    qty: item['checkQty']
                  })
                } else {
                  me.materialDataListNotCheck.push({
                    pid: item.id,
                    packageCode: item.packageCode,
                    'material.name': item['material.name']
                    ,
                    'material.code': item['material.code'],
                    'material.supplier.cnName': item['material.supplier.cnName'],
                    qty: item['checkQty']
                  })
                }
              })
            } else {
              me.materialDataListNotCheck.forEach((item, i) => {
                if (item.packageCode === me.packageCode) {
                  me.materialDataListHadCheck.push(item)
                  me.materialDataListNotCheck.splice(i, 1)

                }
              })
            }
            me.$dialog.alert({
              title: '',
              message: '已扫描',
            }).then(() => {
              me.packageCode = '';

            });
          }
        };
        this.$sapi.callPort(option);
      }else if(this.lastShow ==='sortOrderCode') {
        me.sortOrderCode = text;
        var option2 = {
          portName: 'fr.order.out.SortList.getCustomAndMaterial',
          data: {'customCode': me.sortOrderCode},
          needLoading: true,
          successCallback: function (data) {
            var index = me.customDataList.findIndex(item => {
              if (item.outStockId == data.data.outStockId) {
                return true;
              }
            })
            if (index === -1) {
              me.customDataList.push({
                outStockId: data.data.outStockId,
                outStockName: data.data.outStockName,
                outStockCode: data.data.outStockCode
              })
              data.entities.forEach(item => {
                me.materialDataListHadCheck.push({
                  pid: item.id,
                  packageCode: item.packageCode,
                  'material.name': item['materialLibraryEntity.name'],
                  'material.code': item['materialLibraryEntity.code'],
                  'material.supplier.cnName': item['materialLibraryEntity.supplier.cnName'],
                  'sortCode': item['orderSequence']
                })
              })
            }
            me.$dialog.alert({
              title: '',
              message: '已扫描',
            }).then(() => {
              me.packageCode = '';
            });

          }
        };
        this.$sapi.callPort(option2);
      }
    },
    saveLoadcarOrder() {
      var me = this;
      var option = {
        portName: 'fr.order.out.LoadCarOrder.saveLoadCarOrder',
        data: {
          'id': _.isEmpty(me.loadCarId) ? "":me.loadCarId,
          'name': me.loadCarName,
          'shipperTypeDic.id': me.$data['shipType.id'],
          'typeDic.id': me.$data['transportType.id'],
          driverName: _.isEmpty(me.driverName) ? "":me.driverName,
          headCarCode: _.isEmpty(me.carCode) ? "":me.carCode,
        },
        needLoading: true,
        successCallback: function (data) {
          me.$dialog.alert({
            title: '',
            message: '保存成功',
          }).then(() => {
            me.loadCarCode = data.data.code;
            me.loadCarId = data.data.id
            me.buttonShow=true
          });
        }
      };
      this.$sapi.callPort(option);
    },
    submitLoadCar() {
      var me = this;
      var option = {
        portName: 'fr.order.out.LoadCarOrder.PDAScanSend',
        data: {'loadCarId': me.loadCarId, customList: me.customDataList},
        needLoading: true,
        successCallback: function (data) {
          me.$dialog.alert({
            title: '',
            message: '发货成功',
          }).then(() => {
            me.loadCarCode = data.data.code;
            me.loadCarId = data.data.id
            me.$router.replace("/")
          });
        }
      };

      if (me.materialDataListNotCheck.length > 0) {
        me.$dialog.alert({
          title: '',
          message: '有未扫描物料，请查看并扫描',
        }).then(() => {
          return;
        });
      }
      me.$dialog.confirm({
        title: '',
        message: '确认发货？',
      }).then(() => {
        this.$sapi.callPort(option);
      })

    },
    getScanCode() {
      if (!window.Capacitor) {
        return;
      }
      var me = this;
      Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
        var barcode = data.barcode;
        switch (me.focusModel) {
          case 'packageCode' :
            me.packageCode = barcode;
            var option = {
              portName: 'fr.order.out.PickOrderDetail.getCustomAndMaterial',
              data: {'packageCode': me.packageCode},
              needLoading: true,
              successCallback: function (data) {

                var index = me.customDataList.findIndex(item => {
                  if (item.outStockId == data.data.outStockId) {
                    return true;
                  }
                })
                if (index === -1) {
                  me.customDataList.push({
                    outStockId: data.data.outStockId,
                    outStockName: data.data.outStockName,
                    outStockCode: data.data.outStockCode
                  })
                  data.entities.forEach(item => {
                    if (item.packageCode === me.packageCode) {
                      me.materialDataListHadCheck.push({
                        pid: item.id,
                        packageCode: item.packageCode,
                        'material.name': item['material.name'],
                        'material.code': item['material.code'],
                        'material.supplier.cnName': item['material.supplier.cnName'],
                        qty: item['checkQty']
                      })
                    } else {
                      me.materialDataListNotCheck.push({
                        pid: item.id,
                        packageCode: item.packageCode,
                        'material.name': item['material.name']
                        ,
                        'material.code': item['material.code'],
                        'material.supplier.cnName': item['material.supplier.cnName'],
                        qty: item['checkQty']
                      })
                    }
                  })
                } else {
                  me.materialDataListNotCheck.forEach((item, i) => {
                    if (item.packageCode === me.packageCode) {
                      me.materialDataListHadCheck.push(item)
                      me.materialDataListNotCheck.splice(i, 1)

                    }
                  })
                }
                me.$dialog.alert({
                  title: '',
                  message: '已扫描',
                }).then(() => {
                  me.packageCode = '';

                });
              }
            };
            this.$sapi.callPort(option);
            break;
          case 'sortOrderCode' :
            me.sortOrderCode = barcode;
            var option2 = {
              portName: 'fr.order.out.SortList.getCustomAndMaterial',
              data: {'customCode': me.sortOrderCode},
              needLoading: true,
              successCallback: function (data) {
                var index = me.customDataList.findIndex(item => {
                  if (item.outStockId == data.data.outStockId) {
                    return true;
                  }
                })
                if (index === -1) {
                  me.customDataList.push({
                    outStockId: data.data.outStockId,
                    outStockName: data.data.outStockName,
                    outStockCode: data.data.outStockCode
                  })
                  data.entities.forEach(item => {
                      me.materialDataListHadCheck.push({
                        pid: item.id,
                        packageCode: item.packageCode,
                        'material.name': item['materialLibraryEntity.name'],
                        'material.code': item['materialLibraryEntity.code'],
                        'material.supplier.cnName': item['materialLibraryEntity.supplier.cnName'],
                        'sortCode': item['orderSequence']
                      })
                  })
                }
                  me.$dialog.alert({
                    title: '',
                    message: '已扫描',
                  }).then(() => {
                    me.packageCode = '';
                  });

                }
            };
            this.$sapi.callPort(option2);
            break;
        }
      })
    },
    onTabChange(index) {
      if (index === "unCheck") {
        this.active = 'unCheck';
      }
      if (index === "checked") {
        this.active = 'checked';
      }
    },
  }

}
</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>
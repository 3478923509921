<template>

  <van-swipe-cell>
      <slot></slot>
    <template #right >
      <van-button v-if="canArrive" square type="primary" text="到达"  class="delete-button" @click="arriveConfirm"/>
      <van-button v-if="canDel" square text="删除" type="danger" class="delete-button" @click="delConfirm"/>
      <van-button v-if="canRead" square type="primary" text="审批记录"  class="delete-button" @click="readConfirm"/>
      <van-button v-if="canChange" square type="success" text="变更"  class="delete-button" @click="changeConfirm"/>
      <van-button v-if="canCancel" square type="danger" text="取消叫号"  class="delete-button" @click="cancelConfirm"/>
    </template>
  </van-swipe-cell>

</template>

<script>
export default {
  name: "ListCard",
  props: {
    id: {
      default: "",
      type: String
    },
    onDel:{
      default: null,
      type:Function
    },
    onRead:{
      default: null,
      type:Function
    },
    onChange:{
      default: null,
      type:Function
    },
    onReport:{
      default: null,
      type:Function
    },onCancel:{
      default: null,
      type:Function
    },
    onArrive:{
      default: null,
      type:Function
    },
    canSelect: {
      default: false,
      type: Boolean
    },
    canCancel: {
      default: false,
      type: Boolean
    },
    canArrive: {
      default: false,
      type: Boolean
    },
    arriveCode: {
      default: "",
      type: String
    },
  },
  data:function (){
    return {
      canDel: true,
      canRead: true,
      canChange: true,
  }
  },
  mounted() {
    if(!this.onDel) {
      this.canDel = false
    }
    if(!this.onRead) {
      this.canRead = false
    }
    if(!this.onChange) {
      this.canChange = false
    }
    // if(!this.arrive) {
    //   this.arrive = false
    // }
  },
  methods: {
    delConfirm() {
      this.onDel(this.id);
    },
    readConfirm() {
      this.onRead(this.id);
    },
    changeConfirm() {
      this.onChange(this.id);
    },
    cancelConfirm() {
      this.onCancel(this.id);
    },
    arriveConfirm() {
      this.onArrive(this.id,this.arriveCode);
    },
  }
}
</script>

<style scoped>
.delete-button {
  height: 100%;
}
</style>
